
.main-container {
    overflow: auto;
    position: fixed;
    width: 100%;
    height: 100%;
}

.main-container.container-play {
    overflow: hidden;
}