@font-face {
    font-family: 'Amino';
    src: url('./assets/fonts/Amino-Regular5.eot');
    src: url('./assets/fonts/Amino-Regular5.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Amino-Regular5.woff2') format('woff2'),
        url('./assets/fonts/Amino-Regular5.woff') format('woff'),
        url('./assets/fonts/Amino-Regular5.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Amino';
    src: url('./assets/fonts/Amino-Regular13.eot');
    src: url('./assets/fonts/Amino-Regular13.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Amino-Regular13.woff2') format('woff2'),
        url('./assets/fonts/Amino-Regular13.woff') format('woff'),
        url('./assets/fonts/Amino-Regular13.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Amino';
    src: url('./assets/fonts/Amino-Regular9.eot');
    src: url('./assets/fonts/Amino-Regular9.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Amino-Regular9.woff2') format('woff2'),
        url('./assets/fonts/Amino-Regular9.woff') format('woff'),
        url('./assets/fonts/Amino-Regular9.ttf') format('truetype');
    font-weight: bolder;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Amino';
    src: url('./assets/fonts/Amino-Regular3.eot');
    src: url('./assets/fonts/Amino-Regular3.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Amino-Regular3.woff2') format('woff2'),
        url('./assets/fonts/Amino-Regular3.woff') format('woff'),
        url('./assets/fonts/Amino-Regular3.ttf') format('truetype');
    font-weight: bolder;
    font-style: italic;
    font-display: swap;
}

html {
    height: 100vh;
}

body,
.no-landscape,
.no-desktop {
    height: 100%;
    background-image: url('./assets/images/back.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Amino';
    font-weight: bolder;
    font-style: italic;
    color: red;
    &.dinamica-cerrada {
        text-align: center;
        font-size: 30px;
    }
}

.no-landscape,
.no-desktop {
    position: fixed;
    z-index: 99999;
    width: 100%;
    top: 0;
    left: 0;
    display:none;
}

.centered-leyend {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 100%;
}
.centered-leyend svg {
    width: 100%;
}

@media (min-width: 460px) and (min-height: 460px) {
    .no-desktop { display:block; }
    .main-container { display: none; }
}
/* @media (orientation:landscape) { */
@media screen and (min-aspect-ratio: 13/9) {
    .no-landscape { display:block; }
    .main-container { display: none; }
}
